import React from "react";

const FeaturesTwo = () => {
  return (
    <>
      <section id='robotics' className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
           
              <div className="hero-animation-img">
                <img
                  className="img-fluid d-block m-auto animation-one"
                  src="assets/img/robot1.png"
                  width="140"
                  alt="animation "
                />
                <img
                  className="img-fluid d-none d-lg-block animation-two"
                  src="assets/img/robot2.png"
                  alt="animation "
                  width="120"
                />
               
                <img
                  className="img-fluid d-none d-lg-block animation-four"
                  src="assets/img/robot3.png"
                  alt="animation "
                  width="120"
                />
              </div>
            </div>
           
            <div className="col-md-7" >
              <div className="feature-contents section-heading">
                <h2>
                Machine Learning & AI <br />
                </h2>
                <p>
                 Our engineering team develops ML algorithms that analyze your data in real time. Advances in AI and ML can transform
                 a range of business services—from cybersecurity to operational transformation to clinical research. And we can help you transform your organisation 
                 processes to empower your team to new levels of data at scale. 
                 We’ve developed an intelligent data management methodology and can tailor it to build you a custom AI and ML solution.
                </p>
                <p>
                With  several years of experience in robotics and AI technologies, we have developed handling applications for UAVs, Crawler robots & Gripper robots.
                </p>

                <ul className="list-inline mt-5">
                  <li className="list-inline-item">
                    <div className="mr-3 icon-box border">
                      <img
                        src="assets/img/python.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="mr-3 icon-box border">
                      <img
                        src="assets/img/ros.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="mr-3 icon-box border">
                      <img
                        src="assets/img/gazebo.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="mr-3 icon-box border">
                      <img
                        src="assets/img/tensor.png"
                        alt="icon"
                        className="img-fluid"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesTwo;
