import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import vid from './vid.mp4'
import image from './wavebit_land.png'
import Wave from "react-wavify";

const HeroOne = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section
        className="hero-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/code920.jpg')no-repeat center center / cover",minHeight:'100vh'
        }}
      >
        
        <div className="container" id="home">
          <div className="row align-items-center justify-content-between py-5">
            <div className="col-md-7 col-lg-6">
              <div className="hero-content-left text-white">
                <h1 className="text-white">
                  <span>Innovation
</span>  is the core of everything we do
                </h1>
                <p className="lead">
                Unique businesses and complex challenges require unique solutions.
                </p>

              </div>
            </div>
            <div className="col-md-5 col-lg-5">
              <div className="hero-animation-img">
                <img
                  src={image}
                  alt="app"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
          
        </div>
        <div className="bottom-img-absolute">
        <Wave style={{transform: 'rotate(0deg)'}}
        fill='white'
        paused={false}
        options={{
          height:40,
          amplitude:50,
          speed:0.1,
          points:5
        }}
        />
         
        </div>
      </section>
    </>
  );
};

export default HeroOne;
