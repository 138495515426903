import React from "react";

const Features = () => {
  return (
    <>
      <div className="overflow-hidden">
        <section  className="about-us ptb-100 background-shape-img">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="about-content-left section-heading">
                  <h2>
                  Build next-gen web applications
  <br />   
                  </h2>
                
                  <p style={{textAlign: "justify"}}>We specialize in the development of custom web and mobile applications. 
                    Our team of experienced designers and developers work closely with clients to understand their unique needs and 
                    create solutions that meet their goals and exceed their expectations. We have a proven track record of delivering high-quality, 
                    user-friendly products that drive digital transformation for businesses in a variety of industries.</p>
                  <div className="single-feature mb-4 mt-5">
                    <div className="icon-box-wrap d-flex align-items-center mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="assets/img/image-icon-1.png"
                          alt="icon"
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0"  style={{textAlign: "justify"}}>
                      Our UI/UX design team improves intuitiveness of your product interfaces to create a distinct online presence. 
                      We provide creative and functional UI/UX to engage users.
                      </p>
                    </div>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="assets/img/image-icon-2.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                      REST API development services to meet most urgent needs using industry best practices to build reliable APIs that scale.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="assets/img/image-icon-3.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      <p className="mb-0">
                      Progressive web app development & design services to build high-performance & secure applications that run seamlessly across all platforms.
                      </p>
                    </div>
                    <p></p>
                  </div>
                  <div className="single-feature mb-4">
                    <div className="icon-box-wrap mb-2">
                      <div className="mr-3 icon-box">
                        <img
                          src="assets/img/image-icon-4.png"
                          alt="icon "
                          className="img-fluid"
                        />
                      </div>
                      
                      <p className="mb-0">
                      Web development with easy navigation, robust information architecture, visual cues for users, and action-oriented design. 
                     
                      </p>
                    </div>
                    <p></p>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="about-content-right">
                  <img
                    src="assets/img/test.png"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Features;
