import React from "react";
import logo from "./waveloop_white.png"
import Wave from "react-wavify";

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
     
        <div
          className={`footer-top background-img-2`}
          style={{
            background:
              "url('assets/img/footer-bg.png') no-repeat center top / cover",
          }}
        >
           <Wave style={{transform: 'rotate(180deg)',top:'-1px',position:'relative'}}
           id='wave'
        fill='white'
        paused={false}
        options={{
          height:80,
          amplitude:40,
          speed:0.15,
          points:4
        }}
        />
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src={logo}
                    alt="footer logo"
                    width="150"
                    className="img-fluid mb-3"
                  />
                  <p>
                    Innovation is the core of everything we do
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Linkedin">
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Twitter">
                          <span className="ti-twitter"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="#/" target="_blank" title="Youtube">
                          <span className="ti-youtube"></span>
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Resources</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="#/">Help</a>
                        </li>
                        <li className="mb-2">
                          <a href="#/">Events</a>
                        </li>
                   
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="/#about">About Us</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#services">Software</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#robotics">Robotics</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#R&D">R&D</a>
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Location</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-location-pin mr-2"></span>
                           17 Noemvri 128
                          <br />
                          Greece, 15562
                        </li>
                       
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:mail@example.com">
                            {" "}
                            info@waveloop.net
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a href="#/"> www.waveloop.net</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © 2023 Waveloop 
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

/* <div className="terms-policy-wrap text-lg-right text-md-right text-left">
<ul className="list-inline">
<li className="list-inline-item">
  <a className="small-text" href="#/">
    Terms
  </a>
</li>
<li className="list-inline-item">
  <a className="small-text" href="#/">
    Security
  </a>
</li>
<li className="list-inline-item">
  <a className="small-text" href="#/">
    Privacy Policy
  </a>
</li>
</ul>
</div> */