import React from "react";
import AppRoutes from "./routes/Routes";
import { Helmet} from 'react-helmet';


const App = () => {
  return (
    <div>
     
      <AppRoutes />
      
    </div>
  );
};

export default App;
