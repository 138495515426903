import React from "react";

export default function FeatureImgThree() {
  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/fnol.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
              <h2 id='projecttitle' >Development of a digital traffic accident reporting platform</h2>
              <br/>
                <p>
                Through this digital platform, the user (driver) can report a road accident. The application is connected with insurance companies that provide important information to the user. 
                The user can upload photos of the accident and state the details of the drivers and the existence of any injuries. 
                In addition, the user has the possibility to design the scene of the accident, placing the vehicles and the license plates on a map for better and more accurate visualisation of the accident. 
                <br/>
                All information is stored in a cloud database.
                </p>
 
              </div>
              <h5>Key Features</h5>
                  <ul id='keyfeatures'>
                    <li>Through the application, the user can report a road accident</li>
                    <li>The user selects the insurance company that has a policy and the app displays the insured vehicles in their name along with useful information (driver's license, license plate, etc.)</li>
                    <li>Through the application, the user can upload audiovisual footage from the road accident</li>
                    <li>The user selects the road where the accident occurred and draws the accident conditions on a map, adding vehicles and road signs</li>
                    <li>The application can be used by all devices (smartphone, PC, tablet) as it has a responsive design</li>
                    <li>The user has the option to either download the completed accident report in a pdf form, or email it to someone else</li>
                    <li>Once the user completes the accident report, all information is stored in a cloud database</li>
                    <li>Python (Django framework) was used for the back-end</li>
                    <li>Javascript (React framework) was used for the front-end</li>
                  </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
