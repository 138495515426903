import React from "react";
import { Link } from "react-router-dom";

const Cta = () => {
  return (
    <>
      <section id ="R&D"
        className="download-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/video-bg.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                <span >R&D</span> Consulting                </h2>
                <br/>
                <h4 style={{color:'white'}}>Grant writing</h4>
                <p>
                Do you have an idea but need expert guidance through the EU proposal preparation process? 
                Our consultant group with engineering background will help you shape your project idea into a winning proposal.
                </p>
                <br/>
                <h4 style={{color:'white'}}>Project Partnering</h4>
                <p>
                Are you looking for an EU project partner who can take care of either a project management and dissemination role or technical role?
                </p>

               
              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/consulting.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cta;
