import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import logo from './waveloop_white.png'
import logo2 from './waveloop_black.png'

const Navbar = ({ darkBg, classOption }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);

  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${darkBg ? "bg-transparent" : "custom-nav white-bg"
            } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              
                {scroll > headerTop ? (
                <img
                  src={logo2}
                  id='logo'
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                src={logo}
                id='logo'
                alt="logo"
                className="img-fluid"
                />
              )}
              
            </Link>
            {scroll > headerTop ? (
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"  style={{color:'#25758e'}}></span>
            </button> ) : (
               <button
               className="navbar-toggler"
               type="button"
               
               data-toggle="collapse"
               data-target="#navbarSupportedContent"
               aria-controls="navbarSupportedContent"
               aria-expanded="false"
               aria-label="Toggle navigation"
             >
               <span className="ti-menu"></span>
             </button>

              )}
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown">
                <HashLink className="nav-link" smooth to='/#home'>
                {scroll > headerTop ? (
                <a
                  style={{color:'#26768f'}}
                >Home</a>
              ) : (
                <a
                  style={{color:'white', opacity:'1'}}
                >Home</a>
              )}
                  </HashLink>
                  </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='/#about'>
                  {scroll > headerTop ? (
                <a
                  style={{color:'#26768f'}}
                >Αbout</a>
              ) : (
                <a
                  style={{color:'white', opacity:'1'}}
                >About</a>
              )}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/#services">
                  {scroll > headerTop ? (
                <a
                  style={{color:'#26768f'}}
                >Services</a>
              ) : (
                <a
                  style={{color:'white', opacity:'1'}}
                >Services</a>
              )}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to='/projects'>
                  {scroll > headerTop ? (
                <a
                  style={{color:'#26768f'}}
                >Projects</a>
              ) : (
                <a
                  style={{color:'white', opacity:'1'}}
                >Projects</a>
              )}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="/#contact">
                  {scroll > headerTop ? (
                <a
                  style={{color:'#26768f'}}
                >Contact</a>
              ) : (
                <a
                  style={{color:'white', opacity:'1'}}
                >Contact</a>
              )}
                  </HashLink>
                </li>
             
              
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
